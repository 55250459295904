.centeringContainer {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terminalWindow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 1rem;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

    @media (max-width: 768px) {
        height: 90%;
        width: 90%;
    }

    @media (min-width: 769px) {
        height: 70%;
        max-height: 40rem;
        max-width: 60rem;
        width: 70%;
    }
}

@media print { 
    body * { 
        visibility: hidden;
     }
     html * {
        background-color: white;
     }
    .terminalContent * { visibility: visible; }
    .terminalContent { position: absolute; top: 0px; left: 0px }
}

.terminalContent {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    // IE & Edge
    -ms-overflow-style: none;
    // Firefox
    scrollbar-width: none; 
    // Safari
    &::-webkit-scrollbar {
        display: none;
    }
    // -webkit-overflow-scrolling: auto;
}

.manProgress {
    padding: 0rem 0.5rem;
    color: white;
    background-color: black;
}

.terminalFooter {
    justify-self: end;
    height: auto;
}