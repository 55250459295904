@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Ubuntu Mono', monospace;
  /* color: white; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #E1E1DD; */
  background-color: #F5F2E9;
  line-height: 1rem;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

html {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes App-logo-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}

.splashText{display:inline}.terminalCursor{display:inline;height:1rem;width:auto;vertical-align:text-bottom}

.bold{font-weight:bold}.inheritStyle{text-decoration:none;color:inherit}.marginTop{margin-top:1rem}.manProgress{bottom:0rem}

.manItem{margin-top:1rem}.manTitle{font-weight:bold}.noMarginTop{margin-top:0rem}.manContent{padding-left:4rem}@media (max-width: 768px){.manContent{padding-left:2rem}}

.manHeader{display:flex;justify-content:space-between}

.centeringContainer{height:100vh;height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100);width:100vw;display:flex;justify-content:center;align-items:center}.terminalWindow{display:flex;flex-direction:column;justify-content:space-between;box-sizing:border-box;padding:1rem;background-color:white;box-shadow:0px 0px 10px rgba(0,0,0,0.3)}@media (max-width: 768px){.terminalWindow{height:90%;width:90%}}@media (min-width: 769px){.terminalWindow{height:70%;max-height:40rem;max-width:60rem;width:70%}}@media print{body *{visibility:hidden}html *{background-color:white}.terminalContent *{visibility:visible}.terminalContent{position:absolute;top:0px;left:0px}}.terminalContent{width:100%;height:100%;overflow:hidden;overflow-y:scroll;height:auto;-ms-overflow-style:none;scrollbar-width:none}.terminalContent::-webkit-scrollbar{display:none}.manProgress{padding:0rem 0.5rem;color:white;background-color:black}.terminalFooter{justify-self:end;height:auto}

