.manItem {
    margin-top: 1rem;
} 

.manTitle {
    font-weight: bold;
}

.noMarginTop {
    margin-top: 0rem;
}

.manContent {
    padding-left: 4rem;
    @media (max-width: 768px) {
        padding-left: 2rem;
    }
}