.bold {
    font-weight: bold;
}

.inheritStyle {
    text-decoration: none;
    color: inherit;
}

.marginTop {
    margin-top: 1rem;
}

.manProgress {
    bottom: 0rem;
}