// .splashScreen {
//     display: flex;
//     width: 100vw;
//     height: 100vh;
//     align-items: center;
// }

// .splashTextContainer {
//     padding-left: 8rem;

// }

.splashText {
    display: inline;
}

.terminalCursor {
    display: inline;
    height: 1rem;
    width: auto;
    vertical-align: text-bottom;
}