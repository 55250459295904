@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Ubuntu Mono', monospace;
  /* color: white; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #E1E1DD; */
  background-color: #F5F2E9;
  line-height: 1rem;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

html {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
